@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --global-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  --black-000:  rgb(0, 0, 0);
  --black-15: rgb(15, 15, 15);
  --black-29: rgb(29, 29, 29);
  --black-36: rgb(36, 36, 36);
  --text-light-190: rgb(190, 190, 190);
  --text-white-255: rgb(255, 255, 255);
}

body {
  margin: 0;
  font-family: var(--global-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}

.light-text {
  color: var(--text-light-190);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.black-bg {
  background-color: var(--black-000);
}

.dark-bg {
  background-color: var(--black-15);
}

.mid-dark-bg {
  background: linear-gradient(90deg, var(--black-29) 0%, var(--black-15) 100%);
}




.centre {
  margin-left: auto;
  margin-right: auto;
}

/* page css  */

.page-wrap {
  position: relative;
  min-height: 80vh;
}

.content-wrap {
  padding-bottom: 10vh;
  overflow: hidden;
}

/* nav styles  */

/* .logo {
  height: 100%;
  padding: 0.5rem 0;
} */

.navbar {
  height: 60px ;
  display: flex ;
  justify-content: space-between ;
  padding: 0 calc((100vw - 1000px)/2) ;
  margin-bottom: 40px ;
  position: relative;
  z-index: 12;
}

.nav-menu {
  height: 100%;
}


.navbar .nav-menu li a{
  color: #fff ;
    display: flex ;
    align-items: center ;
    text-decoration    :none ;
    padding: 0 0.6rem ;
    height: 100% ;
    cursor: pointer;
}
.navbar .nav-menu li:active{
    color: rgb(255, 255, 255);
    background-color: rgb(36,36,36);
}
.navbar .nav-menu li:hover{
  color: rgb(255, 255, 255);
  background-color: rgb(29, 29, 29, 0.7);
}

/* nav copy pasta */

.navbar {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: left;
  width: 100%;
}
.navbar-logo {
  height: 100%;
  width: 100%;
  position: relative;
}

.navbar-logo .logo{
  justify-self: left;
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
  height: 100% !important;
  padding: 0.5rem;
  position: relative;
  display: block;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #1888ff;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1000px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    text-align: center;
    padding-top: 30px;
    margin-top: 20px;
  }

  .nav-menu li{
    display: flex;
  }

  .nav-menu li a{
    justify-self: center;
    width: 100%;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;


  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(20px, 20px);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }
  .navbar-logo .logo{
    margin-left: 0;
  }
}


/* footer styles  */

.footer {
  position: absolute;
  left: 0;
  right: 0;
  height: 5vh;
}

.footer-inner {
  position: relative;
  color: var(--text-light-190);
  margin: 0 auto;
  text-align: center;
  display: table;
  padding-bottom: 8vh;

}

.footer-links{
  direction: rtl;
}

.footer-links a{
  display: inline-block;
  margin-left: 1vw;
  margin-right: 1vw;
}

/* page css */

.padding-wrap {
  padding-right: 1vw;
  padding-left: 1vw;
}

.page-inner {
  margin: 0 Auto;
  margin-bottom: 10vh;
  /* padding: 0 1.25rem; */
  padding-left: 1.25rem;
  padding-right: 1.2rem;
}

.page-head-h2 {
  padding-left: 20px;
}

.page-inner p {
  padding-bottom: 2vh;
  color: var(--text-light-190);
}

.about-page .page-inner .page-inner{
  padding: 1.25rem;
  margin-top: 1rem;
}


.role .project{
  transition: all 0.2s ease-in-out;
}

.role:hover, .project:hover, .skill:hover, .back-link:hover, .block-entry:hover, .homefeats:hover{
  background: linear-gradient(90deg, var(--black-36) 0%, var(--black-15) 100%);
  transform: translate(0,-2px);
}

/* rich text styles  */

p a, .rich-link {
  text-decoration: underline !important;
}
p a:hover, .rich-link:hover {
  color: var(--text-white-255) !important;
}

.rich-p{
  color: var(--text-light-190);
}



/* backlinks */

.back-link{
  margin-bottom: 30px;
}

.back-link p{
  margin: 0 auto;
  text-align: center;
  padding-bottom: 0;
}

.back-link:hover {
  color: var(--text-white-255);
}

/* skill styles */

.skill {
  border: 1px;
  border-style: solid;
  border-color: #fff;
  overflow: auto;
}

.skill-left {
  float: left;
}

.skill img {
  max-height: 60px;
  object-fit: contain;
}



/* block entry css */

.block-entry {
  border: 1px;
  border-style: solid;
  border-color: #fff;
  overflow: auto;
  margin-bottom: 1vh;
  margin-top: 1vh;
  color: var(--text-light-190);
}

.block-entry-left {
  float: left;
  width: 75%;
}

.block-entry-right{
  float: right;
  max-width: 20%;
  margin-left: 1vw;
}

.related-title {
  font-weight: bold;
  color: var(--text-white-255);
}

/* home styles */

.home-section {
  margin: 0 auto;
}

.hero-text {
  width: 100%;
  position: relative;

}

.home-typer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 50px;
  text-align: center;
}

.home-hero-text {
  /* display: none; */
}

.hero-text-wrap {
  margin: auto;
}

.parallax-inner{
 padding: 20px 0px;
}

.hero-text-wrap h1, .hero-text-wrap h2 {
  text-align: center;
}


/* media calls*/

@media screen and (max-width: 500px) {
  .parallax-inner{
    padding: 15vh 0 10vh 0;
   }
  /* .nav-links a li {
    font-size: ;
  } */
}
@media screen and (min-width: 500px) {
  .parallax-inner{
    padding: 20vh 0 16vh 0;
   }
  /* .nav-links a li {
    font-size: ;
  } */
}

@media screen and (max-width: 700px) {
  .block-entry-left{
    width: 100% !important;
    float: none !important;
  }
  .block-entry-right{
    max-width: 50% !important;
    width: auto !important;
    float: none !important;
    margin: 0 auto !important;
  }
}

@media screen and (min-width: 800px) {
  .rich-img {
    margin-left: 15%;
    width: 70%;
  }
  .role-inner div:nth-child(n+6) {
    /* color: red !important; */
    max-width: 70%;
    margin-left: 15%;
  }

  .skill-inner div:nth-child(n+4) {
    /* color: red !important; */
    margin-top: 1vh;
    max-width: 70%;
    margin-left: 15%;
  }
}

.space {

  width: 100%;
  height: 40px;
}

.home-about {
  width: 100%;
}

.home-page {
  margin-top: -100px;
  z-index: -1;
}

.react-parallax img{
  object-fit: cover;
}

.menu-item-active{
  background-color: red;
  color: #1888ff;
}

.a1 {

}

.feat-img img{
  object-fit: cover;
}

